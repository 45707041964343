import merge from 'lodash/merge';
import config from './config';

const environments = {
  localhost: 'localhost',
  'staging-devops.citadel.tools': 'staging',
  'devops.citadel.tools': 'production',
};

// https://cli.vuejs.org/guide/mode-and-env.html#modes
let environment;

// NODE_ENV = test|development|production
if (process.env.NODE_ENV === 'test') {
  environment = 'test';
} else if (typeof window !== 'undefined') {
  environment = environments[window.location.hostname];
}

if (!environment) {
  throw new Error('Failed to set the environment');
}

function requireIfExists(path) {
  let mod;
  try {
    // eslint-disable-next-line import/no-dynamic-require,global-require
    mod = require(path);
    // eslint-disable-next-line no-underscore-dangle
    if (mod.__esModule) {
      mod = mod.default;
    }
  } catch (error) {
    if (error.code !== 'MODULE_NOT_FOUND') {
      throw error;
    }
  }
  return mod;
}

const isTest = environment === 'test';
let envConfig = require(`./config.${environment}`);
if (envConfig.__esModule) {
  envConfig = envConfig.default;
}

// eslint-disable-next-line import/no-dynamic-require
let configLocal = {};

// this code looks weird but is like this because of how webpack handles dynamic requires
if (process.env.NODE_ENV === 'development') {
  try {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line global-require
      configLocal = require('./config.local');
    } else if (process.env.NODE_ENV === 'test') {
      configLocal = require('./config.local.test');
    }
    if (configLocal.__esModule) {
      configLocal = configLocal.default;
    }
  } catch (error) {
    if (error.code !== 'MODULE_NOT_FOUND') {
      throw error;
    }
  }
}

// const mergedConfig = merge({}, config, envConfig);

export default {
  environment,
  ...config,
  ...envConfig,
  ...configLocal,
};
