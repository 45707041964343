<template>
  <div class="login-page">
    <div class="login-container">
      <v-row>
        <v-col>
          <v-card tile class="mr-4">
            <v-card-text style="min-height: 310px">
              <div class="overline mb-4">Login To Your Account</div>
              <v-form
                id="login-form"
                ref="loginForm"
                v-model="isValid"
                @submit.prevent="onSubmit"
              >
                <v-text-field
                  v-model.trim="user.username"
                  :rules="rules.username"
                  label="Username"
                  placeholder="Username"
                  solo
                ></v-text-field>
                <v-text-field
                  v-model.trim="user.password"
                  :rules="rules.username"
                  label="Password"
                  placeholder="Password"
                  type="password"
                  solo
                ></v-text-field>
                <v-text-field
                  v-model.trim="user.multiFactorToken"
                  :rules="rules.multiFactorToken"
                  label="2-factor code"
                  placeholder="2-factor code"
                  solo
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="pa-4 pt-0">
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                form="login-form"
                :loading="loading"
                :disabled="loading"
                color="primary"
              >
                Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

    </div>
  </div>
</template>

<style lang="scss" scoped>
  .logo {
    position: relative;
    padding: 10px 0 0 47px;
    margin-bottom: 30px;
    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .login-page {
    margin: 20px auto;
    max-width: 800px;
  }
</style>

<script>
  import validations from '@/@libs/validations';
  import config from '../../../config';

  export default {
    name: 'login',
    components: {
    },
    data() {
      return {
        isValid: false,
        loading: false,
        rules: {
          username: [validations.required()],
          password: [validations.required()],
        },
        user: {
          username: config.defaultCredentials.username || '',
          password: config.defaultCredentials.password || '',
          multiFactorToken: '',
        },
      };
    },
    methods: {
      async onSubmit() {
        if (this.$refs.loginForm.validate()) {
          this.loading = true;
          try {
            await this.$store.dispatch('users/login', { user: this.user });
            await this.$router.replace({ name: 'dashboard' })
              .catch((e) => {
                // https://github.com/vuejs/vue-router/issues/2873
                console.error(`Ignored error from $router.replace: ${e && e.message}`);
              });
          } finally {
            this.loading = false;
          }
        }
      },
    },
  };
</script>
