import colors from '../../@libs/colors';

import { HttpNotAuthorizedError } from '../../@constants/errors';
import auth from '../../@libs/auth';
import http from '../../@libs/http';

const usersModule = {
  namespaced: true,
  state: {
    user: false,
    activeTimeEntry: null,
    employee: null,
  },
  // mutations, actions, getters...
  getters: {
    userId: (state) => {
      const { user } = state;
      return user && user.id;
    },
    role: (state) => {
      const { user } = state;
      return user && user.role;
    },
    user: (state) => {
      const { user } = state;
      return user;
    },
  },
  mutations: {
    setUser(state, _user) {
      let user = _user;
      if (user) {
        user = {
          ...user,
          color: colors.getColor({ colorId: user.colorId }),
        };
      }
      state.user = user;
    },
  },
  actions: {
    async logout({ commit }) {
      auth.removeToken();
      commit('setUser', null);
    },
    async loadUser({ commit }) {
      let user = auth.getPayload();
      if (user) {
        let serverUser;

        try {
          serverUser = await http.get(`/users/${user.id}`);
        } catch (error) {
          if (error.code === 'HTTP_NOT_FOUND') {
            auth.removeToken();
            throw new HttpNotAuthorizedError();
          }
          throw error;
        }

        user = serverUser;
      }
      commit('setUser', user);
    },
    async login({ commit }, payload) {
      const { username, password, multiFactorToken } = payload.user;
      const user = await http.post('/login', {
        body: {
          username,
          password,
          multiFactorToken,
        },
      });
      commit('setUser', user);
    },
  },
};

export default usersModule;
