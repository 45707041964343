<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-app v-if="user">
    <v-app-bar
      app
      color="primary"
      dark
      flat
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        to="/"
      >
        Dashboard
      </v-btn>
      <v-btn
        text
        to="/users"
      >
        Users
      </v-btn>
      <UserMenu></UserMenu>
    </v-app-bar>

    <v-main>
      <slot />
    </v-main>
  </v-app>
  <v-app v-else>
    <div>loading ...</div>
  </v-app>
</template>

<style lang="scss">

</style>

<script>
  import UserMenu from './UserMenu';

  export default {
    name: 'DefaultLayout',
    components: {
      UserMenu,
    },
    data() {
      return {
        drawer: true,
      };
    },
    created() {
      if (this.isMobile) {
        this.drawer = false;
      }
    },
    computed: {
      showOverlay() {
        return this.isMobile && this.drawer;
      },
      isMobile() {
        return this.$vuetify.breakpoint.xs;
      },
      user() {
        return this.$store.state.users.user;
      },
    },
    methods: {
    },
  };
</script>
