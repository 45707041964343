<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="data-table">
    <c-table
      v-on="$listeners"
      :id="id"
      :url="url"
      :onRowClick="onRowClick"
      :selectedRows="selectedRows"
      :columns="columns"
      :actions="actions"
      :filters="filters"
      :itemsPerPageOptions="itemsPerPageOptions"
      :selectedFilters.sync="$_selectedFilters"
      :search="search"
      :loading="$_loading"
      :items="filteredItems"
      :groupBy="groupBy"
      :uid="uid"
      :expanded="expanded"
      :options.sync="options"
      :count="count"
      :elevation="elevation"
      :margin="margin"
      :searchTerm.sync="searchTerm"
      :expandable="expandable"
      :disablePagination="disablePagination"
      @delete="onDelete"
      @refetch="fetchData"
    >
      <template
        v-for="(_, slot) of $scopedSlots"
        v-slot:[slot]="scope"
      >
        <slot :name="slot" v-bind="scope"/>
      </template>
    </c-table>
  </div>
</template>

<script>
  import keyBy from 'lodash/keyBy';
  import isEqual from 'lodash/isEqual';
  import debounce from 'lodash/debounce';
  import http from '@/@libs/http';
  import bus from '@/@libs/bus';
  import searchLib from '@/@libs/search';

  export default {
    name: 'CDataTable',
    props: {
      elevation: Number,
      margin: Number,
      id: String,
      expanded: Object,
      groupBy: [String, Function, Array],
      columns: Array,
      actions: Array,
      filters: Array,
      itemsPerPageOptions: Array,
      selectedFilters: Array,
      search: Boolean,
      loading: Boolean,
      url: String,
      loadAll: Boolean,
      expandable: Boolean,
      uid: String,
      disablePagination: Boolean,
      items: Array,
      mapItems: Function,
      filter: Function,
      onRowClick: Function,
      selectedRows: Array,
      inMemory: Boolean,
    },
    data() {
      return {
        dataId: 0,
        searchTerm: '',
        count: undefined,
        localLoading: false,
        localItems: [],
        localSelectedFilters: [],
        options: {},
      };
    },
    created() {
      // fetch the data when the view is created and the data is
      // already being observed
      this.searchTerm = this.querySearchTerm;
      return this.fetchData();
    },
    computed: {
      querySearchTerm() {
        return this.$route.query.searchTerm || '';
      },
      $_items: {
        get() {
          return this.items || this.localItems;
        },
        set(value) {
          if (this.items) {
            this.$emit('update:items', value);
          } else {
            this.localItems = value;
          }
        },
      },
      filtersById() {
        return keyBy(this.filters || [], 'id');
      },
      nonManualSelectedFilters() {
        // some filters have manualFilter so we can do more complex custom manual filtering
        const selectedFilters = this.$_selectedFilters || [];
        return selectedFilters.filter(({ id }) => {
          return !this.filtersById[id].manualFilter;
        });
      },
      manualSelectedFilters() {
        // some filters have manualFilter so we can do more complex custom manual filtering
        const selectedFilters = this.$_selectedFilters || [];
        return selectedFilters.filter(({ id }) => {
          return this.filtersById[id].manualFilter;
        });
      },
      filteredItems() {
        const items = this.$_items;
        if (this.loadAll && this.$_selectedFilters && this.$_selectedFilters.length) {
          let filteredItems = searchLib.filter(items, this.nonManualSelectedFilters);
          if (this.filter) {
            filteredItems = this.filter(
              filteredItems,
              this.manualSelectedFilters,
              items,
              this.nonManualSelectedFilters,
            );
          }
          return filteredItems;
        }
        return items;
      },
      $_loading: {
        get() {
          return this.loading || this.localLoading;
        },
        set(value) {
          if (this.loading) {
            this.$emit('update:loading', value);
          } else {
            this.localLoading = value;
          }
        },
      },
      $_selectedFilters: {
        get() {
          return this.selectedFilters || this.localSelectedFilters;
        },
        set(value) {
          if (this.selectedFilters) {
            this.$emit('update:selectedFilters', value);
          } else {
            this.localSelectedFilters = value;
          }
        },
      },
    },
    watch: {
      // call again the method if the route changes
      url: 'fetchData',
      $_selectedFilters() {
        if (this.options.page !== 1) {
          this.options.page = 1;
        }
        if (!this.loadAll) {
          this.fetchData();
        }
      },
      // although we debounce fetchData we want a longer debounce for when typing
      searchTerm: debounce(function () {
        if (this.options.page !== 1) {
          this.options.page = 1;
        }
        if (!this.loadAll) {
          this.fetchData();
        }
      }, 500),
      options: {
        handler(options, prevOptions) {
          if (this.loadAll) {
            return;
          }
          const fields = ['page', 'itemsPerPage', 'sortBy', 'sortDesc'];
          const diff = fields.find((field) => {
            // todo: for arrays it will be a bit different
            return !isEqual(options[field], prevOptions[field]);
          });
          if (diff) {
            this.fetchData();
          }
        },
        deep: true,
      },
    },
    methods: {
      async onDelete(item) {
        const hard = item.isDeleted === true;
        bus.trigger('confirm', {
          title: `Are you sure you want to ${hard ? 'hard ' : ''}delete this item?`,
          text: `This action will ${hard ? 'permanently ' : ''} delete this item.`,
          confirmText: `${hard ? 'Hard ' : ''}Delete`,
          cancelText: 'Cancel',
          maxWidth: hard ? 500 : 450,
          confirmColor: hard ? 'error' : 'primary',
          onSelect: async (activate) => {
            if (activate) {
              this.$_loading = true;
              try {
                await http.delete(`${this.url}/${item.id || item._id}`, {
                  qs: {
                    hard,
                  },
                });
                await this.fetchData();
              } finally {
                this.$_loading = false;
              }
            }
          },
        });
      },
      cancelFetch() {
        this.dataId += 1;
        return this.dataId;
      },
      async fetchData(options = {}) {
        if (this.inMemory) {
          return;
        }
        const { noCache } = options;
        if (!this.loadAll && (!this.url || !this.options.sortBy)) {
          // url and options have not been initialized yet
          return;
        }
        const dataId = this.cancelFetch();
        this.$_loading = true;
        try {
          let httpOptions;
          if (!this.loadAll) {
            httpOptions = {
              noCache,
              qs: {
                searchTerm: this.searchTerm,
                limit: this.options.itemsPerPage,
                page: this.options.page,
                sort: this.options.sortBy.map((column, index) => ({
                  column,
                  desc: this.options.sortDesc[index],
                })),
                filters: this.$_selectedFilters,
              },
            };
          } else {
            httpOptions = {
              noCache,
            };
          }
          const result = await http.get(this.url, {
            ...httpOptions,
          });
          // we make sure that this is the newest requested fetchData otherwise ignore the result
          if (dataId === this.dataId) {
            this.$_items = this.mapItems ? this.mapItems(result.data) : result.data;
            if (!this.loadAll) {
              this.count = result.count;
            }
          }
        } finally {
          this.$_loading = false;
        }
      },
    },
  };
</script>
