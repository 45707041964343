import colors from '@/@constants/colors';

export default {
  getColor({ colorId }) {
    if (typeof colorId !== 'number') {
      return 'grey';
    }
    const colorIndex = colorId % colors.avatar.length;
    return colors.avatar[colorIndex];
  },
};
