<template>
  <div>
    <c-toolbar
      title="Dashboard"
    />
    <c-form-box title="Projects" class="ma-3">
      <ProjectsTable />
    </c-form-box>
  </div>
</template>

<style lang="scss">
</style>

<script>
  import http from '@/@libs/http';
  import bus from '@/@libs/bus';
  import ProjectsTable from './ProjectsTable';

  export default {
    name: 'Dashboard',
    components: {
      ProjectsTable,
    },
    props: {},
    created() {
      this.loadData();
    },
    data() {
      return {
      };
    },
    computed: {
    },
    methods: {
      async loadData() {
        // const [projects] = await Promise.all([
        //   http.get('/projects'),
        // ]);
        // this.projects = projects;
      },
    },
  };
</script>
