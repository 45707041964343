<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-sheet :elevation="elevation" :class="`ma-${margin}`">
    <Filters
      :id="id"
      v-if="filters && filters.length"
      v-on="$listeners"
      :filters="filters"
      :show.sync="showFilters"
      :selectedFilters="selectedFilters"
    />
    <v-toolbar flat color="white">
      <v-text-field
        v-if="search && !isMobile"
        class="ma-1"
        :value="searchTerm"
        @input="$emit('update:searchTerm', $event)"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>

      <v-tooltip bottom v-for="(action, index) in actions" :key="index">
        <template
          v-slot:activator="{ on }"
        >
          <v-btn
            v-on="on"
            class="ma-0"
            color="action"
            :disabled="action.bulk && !$$selectedRows.length"
            @click="action.emit ? $emit(action.emit, action) : action.onClick(action)"
            icon
          >
            <v-icon :color="action.color">{{action.icon}}</v-icon>
          </v-btn>
        </template>
        <span>{{action.text}}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template
          v-slot:activator="{ on }"
        >
          <v-btn
            class="ma-0"
            color="action"
            @click="$emit('refetch')"
            v-on="on"
            icon
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh Data</span>
      </v-tooltip>
      <v-tooltip bottom v-if="filters && filters.length">
        <template
          v-slot:activator="{ on }"
        >
          <v-btn
            class="ma-0"
            color="action"
            @click="showFilters = true"
            v-on="on"
            icon
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        <span>Filter</span>
      </v-tooltip>
      <ColumnsMenu :id="id" :selectedColumnIds.sync="selectedColumnIds" :columns="columns" />
    </v-toolbar>
    <v-toolbar flat color="white" v-if="search && isMobile">
      <v-text-field
        class="ma-1"
        :value="searchTerm"
        @input="$emit('update:searchTerm', $event)"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-toolbar>
    <v-divider></v-divider>

    <v-data-table
      :search="searchTerm"
      :headers="selectedColumns"
      :items="mappedItems"
      :loading="loading"
      :disable-pagination="disablePagination"
      :hide-default-footer="disablePagination"
      class="elevation-1"
      :options="options"
      v-on="$listeners"
      :server-items-length="count"
      :footer-props="footerProps"
    >
      <template
        v-for="(_, slot) of $scopedSlots"
        v-slot:[slot]="scope"
      >
        <slot :name="slot" v-bind="scope"/>
      </template>
      <template v-slot:header.bulkSelect>
        <v-checkbox
          v-model="bulkSelectAll"
        ></v-checkbox>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
        <tr v-if="loading && !items.length">
          <td :colspan="selectedColumns.length">
            <div class="text-center">loading...</div>
          </td>
        </tr>
        <template v-for="row in items">
          <tr class="group-header-row" :key="`${row.$groupName}_group`" v-if="row.$type === 'group'">
            <td :colspan="selectedColumns.length" @click="toggleGroupExpand(row.$groupName)">
              <slot
                name="group_header"
                v-bind:groupName="row.$groupName"
                v-bind:group="row.$group"
                v-bind:row="row"
                v-bind:toggleExpand="toggleExpand"
                v-bind:emit="emit"
              >
                {{row.$groupName}}
              </slot>
              <!--<v-icon>
                mdi-{{groupCollapsed[row.$groupName] ? 'plus' : 'minus'}}
              </v-icon>-->
            </td>
          </tr>
          <tr
            :key="`${row.$uid}_main`"
            v-if="row.$type === 'item' && !groupCollapsed[row.$groupName]"
            v-on="onRowClick ? {click: () => onRowClick({ row, item: row.$item, toggleExpand }) } : {}"
          >
            <td
              v-for="(column, index) in selectedColumns"
              :key="index"
              :class="{'text-right': column.align === 'right'}"
              :style="column.onClick ? 'cursor: pointer' : ''"
              v-on="column.onClick ? {click: () => column.onClick({ row, item: row.$item, toggleExpand, column }) } : {}"
            >
              <slot
                :name="`item.${column.id}`"
                v-bind:item="row.$item"
                v-bind:row="row"
                v-bind:toggleExpand="toggleExpand"
                v-bind:column="column"
                v-bind:emit="emit"
              >
                <template v-if="column.actions">
                  <ColumnAction
                    :id="id"
                    v-for="(action, index) in column.actions"
                    :key="index"
                    :emit="action.emit"
                    :onClick="action.onClick"
                    :icon="action.icon"
                    :text="action.text"
                    :item="row.$item"
                    :type="action.type"
                    :color="action.color"
                    v-on="$listeners"
                    :show="!action.condition || action.condition(row.$item)"
                  >
                  </ColumnAction>
                </template>
                <template v-else-if="column.type === 'checkbox'">
                  <v-checkbox
                    :input-value="column.checked(row.$values)"
                    @change="column.onChange($event, row.$values)"
                  ></v-checkbox>
                </template>
                <template v-else-if="column.type === 'bulkSelect'">
                  <v-checkbox
                    :input-value="!!selectedRowsMap[row.$values[uid || 'id']]"
                    @change="toggleSelectedRow(row.$values)"
                  ></v-checkbox>
                </template>
                <template v-else-if="column.type === 'expand'">
                  <v-icon @click="toggleExpand(row, index)">
                    mdi-chevron-{{$$expanded && $$expanded.$uid === row.$uid ? 'up' : 'down'}}
                  </v-icon>
                </template>
                <template v-else-if="column.link">
                  <router-link
                    :to="column.link(row.$values)"
                  >
                    {{row.$values[column.value]}}
                  </router-link>
                </template>
                <template v-else-if="column.type === 'boolean'">
                  <v-icon v-if="row.$values[column.value]" color="green">mdi-check</v-icon>
                  <v-icon v-else color="red">mdi-close</v-icon>
                </template>
                <template v-else-if="column.type === 'positive'">
                  <div>
                    <v-icon v-if="row.$values[column.value]" color="green">mdi-check</v-icon>
                  </div>
                </template>
                <template v-else-if="column.type === 'negative'">
                  <div>
                    <v-icon v-if="row.$values[column.value]" color="red">mdi-alert-decagram</v-icon>
                  </div>
                </template>
                <template v-else-if="column.type === 'number'">
                  <div>
                    {{row.$values[column.value] | toLocaleString}}
                  </div>
                </template>
                <template v-else-if="column.type === 'roundNumber'">
                  <div>
                    {{Math.round(row.$values[column.value] * (10 * (column.decimalPoints || 1))) / (10 * (column.decimalPoints || 1))}}
                  </div>
                </template>
                <template v-else-if="column.type === 'date'">
                  {{row.$values[column.value] ? (new Date(row.$values[column.value])).toLocaleString() : ''}}
                </template>
                <template v-else-if="column.type === 'milliseconds'">
                  {{(new Date(Math.round((row.$values[column.value] || 0) * 1000))).toLocaleString()}}
                </template>
                <template v-else-if="column.type === 'currency'">
                  <span v-if="column.ignoreZero && !row.$values[column.value]">-</span>
                  <span v-else>{{row.$values[column.value] | toCurrency}}</span>
                </template>
                <template v-else-if="column.type === 'satoshi'">
                  {{row.$values[column.value] | toBtc}}
                </template>
                <template v-else-if="column.type === 'cents'">
                  {{(row.$values[column.value] || 0) / 100 | toCurrency}}
                </template>
                <template v-else-if="column.type === 'tags'">
                  <v-chip
                    class="ma-1"
                    small
                    v-for="(tag, index) in row.$values[column.value]"
                    :key="index"
                    @click="column.onClick ? column.onClick(tag) : () => {}"
                  >
                    {{tag}}
                  </v-chip>
                </template>
                <template v-else-if="column.type === 'objectTags'">
                  <v-chip
                    class="ma-1"
                    small
                    :color="tag.tagColor"
                    v-for="(tag, index) in row.$values[column.value]"
                    :key="index"
                    @click="column.onClick ? column.onClick(tag) : () => {}"
                  >
                    <span :style="{color: tag.textColor }">{{tag.tagName}}</span>
                  </v-chip>
                </template>
                <template v-else-if="column.to">
                  <router-link :to="column.to(row.$item)">
                    {{row.$values[column.value]}}
                  </router-link>
                </template>
                <template v-else-if="column.value">
                  {{column.map ? column.map(row.$item) : row.$values[column.value]}}
                </template>
              </slot>

            </td>
          </tr>
          <tr
            :key="`${row.$uid}_expanded`"
            v-if="row.$type === 'item' && $$expanded && $$expanded.$uid === row.$uid"
          >
            <slot
              name="expanded-item"
              v-bind:item="row.$item"
              v-bind:headers="selectedColumns"
            >
              <td :colspan="selectedColumns.length">
                expanded-item slot missing
              </td>
            </slot>
          </tr>
        </template>
        </tbody>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{pageStart}}-{{pageStop}} {{itemsLength === 99999123 ? '' : `of ${itemsLength}`}}
      </template>
    </v-data-table>
  </v-sheet>
</template>

<style lang="scss" scoped>
  .group-header-row {
    background: #eeeeee !important;
    /*color: #fff;*/
    cursor: pointer;
  }
</style>

<script>
  import get from 'lodash/get';
  import keyBy from 'lodash/keyBy';
  import ColumnsMenu from './ColumnsMenu';
  import ColumnAction from './ColumnAction';
  import Filters from './Filters';

  export default {
    name: 'Table',
    components: {
      ColumnsMenu,
      ColumnAction,
      Filters,
    },
    props: {
      elevation: {
        type: Number,
        default: 4,
      },
      margin: {
        type: Number,
        default: 4,
      },
      id: String,
      url: String,
      expanded: Object,
      groupBy: [String, Function, Array],
      columns: Array,
      actions: Array,
      filters: Array,
      selectedFilters: Array,
      search: Boolean,
      expandable: Boolean,
      disablePagination: Boolean,
      items: Array,
      loading: Boolean,
      options: Object,
      count: Number,
      searchTerm: String,
      uid: String,
      itemsPerPageOptions: Array,
      selectedRows: Array,
      onRowClick: Function,
    },
    data() {
      return {
        bulkSelectAll: false,
        showFilters: false,
        localExpanded: null,
        localSelectedRows: [],
        selectedColumnIds: [],
        groupCollapsed: {},
        footerProps: {
          'items-per-page-options': this.itemsPerPageOptions || (this.showSpreadsheet ? [20, 50, 100, 250] : [20, 50, 100]),
        },
      };
    },
    created() {
      this.selectedColumnIds = this.columns.filter(c => c.default).map(c => c.id);
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xs;
      },
      $$expanded: {
        get() {
          return this.expanded || this.localExpanded;
        },
        set(value) {
          if (typeof this.expanded !== 'undefined') {
            this.$emit('update:expanded', value);
          } else {
            this.localExpanded = value;
          }
        },
      },
      selectedRowsMap() {
        return keyBy(this.$$selectedRows, this.uid || 'id');
      },
      $$selectedRows: {
        get() {
          return this.selectedRows || this.localSelectedRows;
        },
        set(value) {
          if (Array.isArray(this.selectedRows)) {
            this.$emit('update:selectedRows', value);
          } else {
            this.localExpanded = value;
          }
        },
      },
      mappedItems() {
        let mappedItems = [];
        const getUID = item => (this.uid && item[this.uid] ? item[this.uid] : Math.random());
        const getValues = (item) => {
          const result = {
            ...item,
          };
          this.selectedColumns.forEach((col) => {
            result[col.value] = get(item, col.value);
          });
          return result;
        };
        if (this.groupBy) {
          let groups;
          if (typeof this.groupBy === 'function') {
            groups = this.groupBy(this.items);
          } else if (typeof this.groupBy === 'string') {
            const groupMap = {
              '': [],
            };
            this.items.forEach((item) => {
              const key = item[this.groupBy] || '';
              if (!groupMap[key]) {
                groupMap[key] = [];
              }
              groupMap[key].push(item);
            });
            groups = Object.keys(groupMap).map(key => ({
              name: key,
              items: groupMap[key],
            }));
          }
          // don't use groups if we only have one
          if (groups) {
            const nonDefaultGroupsWithItemsCount = groups.filter((group) => {
              return group.name && group.items.length;
            }).length;
            groups.forEach((group) => {
              if (nonDefaultGroupsWithItemsCount && group.items.length) {
                mappedItems.push({
                  $type: 'group',
                  $groupName: group.name,
                  $group: group,
                });
              }
              group.items.forEach((item) => {
                const $values = getValues(item);

                mappedItems.push({
                  ...$values,
                  $type: 'item',
                  $item: item,
                  $values,
                  $groupName: group.name,
                  $group: group,
                  $uid: `${group.name}_${getUID(item)}`,
                });
              });
            });
            return mappedItems;
          }
        } else {
          mappedItems = this.items.map((item) => {
            const $values = getValues(item);

            return {
              ...$values, // needed for search to work
              $type: 'item',
              $item: item,
              $values,
              $uid: getUID(item),
            };
          });
        }

        return mappedItems;
      },
      selectedColumns() {
        return this.columns
          .filter((c) => (this.selectedColumnIds.indexOf(c.id) > -1 && !c.hide))
          .map((column) => {
            // disable sorting. In the future we might want to build sorting capability for groups
            if (this.groupBy) {
              return {
                ...column,
                sortable: false,
              };
            }
            return column;
          });
      },
    },
    watch: {
      bulkSelectAll: 'toggleSelectedAll',
      items: 'filterSelectedRows',
      options: {
        handler: 'filterSelectedRows',
        deep: true,
      },
    },
    methods: {
      emit(key, value) {
        this.$emit(key, value);
      },
      toggleSelectedAll(selectAll) {
        if (!selectAll) {
          this.$$selectedRows = [];
        } else {
          this.$$selectedRows = this.getAllItemsInPage();
        }
      },
      getAllItemsInPage() {
        const allItems = this.items;
        let items;
        // if this.count is set we are have not loaded all the items
        if (this.count) {
          items = allItems;
        } else {
          const page = this.options.page || 1;
          const itemsPerPage = this.options.itemsPerPage;
          const start = (page - 1) * itemsPerPage;
          const end = start + itemsPerPage;
          items = allItems.slice(start, end);
        }
        return items;
      },
      filterSelectedRows() {
        const allItems = this.items;
        if (!allItems || !this.$$selectedRows || !this.$$selectedRows.length) {
          return;
        }
        const itemsInPage = this.getAllItemsInPage();

        const key = this.uid || 'id';
        const map = keyBy(itemsInPage, key);
        this.$$selectedRows = this.$$selectedRows
          .filter((row) => {
            return !!map[row[key]];
          })
          // we need to map to get new items in case there was an update that happened
          .map((row) => {
            return map[row[key]];
          });
      },
      toggleSelectedRow(row) {
        const key = this.uid || 'id';
        const index = this.$$selectedRows.findIndex(item => row[key] === item[key]);
        if (index > -1) {
          this.$$selectedRows = [
            ...this.$$selectedRows.slice(0, index),
            ...this.$$selectedRows.slice(index + 1),
          ];
        } else {
          this.$$selectedRows = this.$$selectedRows.concat(row);
        }
      },
      toggleExpand(row) {
        this.$$expanded = this.$$expanded && this.$$expanded.$uid === row.$uid ? null : row;
      },
      toggleGroupExpand(name) {
        this.groupCollapsed = {
          ...this.groupCollapsed,
          [name]: !this.groupCollapsed[name],
        };
      },
    },
  };
</script>
