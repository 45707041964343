<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-menu
      offset-y
      v-model="showMenu"
      :close-on-content-click="false"
      v-if="user"
    >
      <template v-slot:activator="{ on }">
        <v-btn text icon color="indigo" class="header-user-avatar" v-on="on">
          <v-avatar
            :color="user.color"
            size="28"
          >
            <span class="white--text">{{abbreviation}}</span>
          </v-avatar>
        </v-btn>
      </template>

      <v-card
      >
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="headline">{{name}}</v-list-item-title>
            <v-list-item-subtitle>{{role}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <div class="items ma-2">
          <div class="item ma-1">
            <a href="#" @click.prevent="openUserProfile(0)">Edit profile</a>
          </div>
          <div class="item ma-1">
            <a href="#" @click.prevent="openUserProfile(1)">Change password</a>
          </div>
          <div class="item ma-1">
            <a href="#" @click.prevent="openUserProfile(2)">Setup 2fa</a>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            text
            tile
            @click="logout"
          >
            Logout
            <v-icon small right>mdi-logout</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-dialog
      v-model="showUserProfile"
      scrollable
      persistent
    >
      <user-profile
        v-if="showUserProfile"
        :tab.sync="userProfileTab"
        @cancel="showUserProfile = false"
        @saved="showUserProfile = false"
        :show="showUserProfile"
      ></user-profile>
    </v-dialog>
  </div>
</template>

<script>
  import UserProfile from './UserProfile';

  export default {
    name: 'UserMenu',
    components: {
      'user-profile': UserProfile,
    },
    data() {
      return {
        showMenu: false,
        showUserProfile: false,
        userProfileTab: null,
      };
    },
    computed: {
      role() {
        const roles = this.$store.state.constants.roles;
        const user = this.user;
        if (!user || !roles) {
          return '';
        }
        return roles[user.role].text;
      },
      user() {
        return this.$store.state.users.user;
      },
      name() {
        const user = this.user;
        if (!user) {
          return '';
        }
        return `${user.firstName} ${user.lastName}`;
      },
      abbreviation() {
        const user = this.user;
        if (!user) {
          return '';
        }
        return `${user.firstName[0]}${user.lastName[0]}`.toUpperCase();
      },
    },
    methods: {
      openUserProfile(tab) {
        this.showUserProfile = true;
        this.userProfileTab = tab;
      },
      async logout() {
        this.$store.dispatch('users/logout');
        await this.$router.replace({ name: 'login' });
      },
    },
  };
</script>
