<template>
  <div>
    <c-form-box title="environments">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
          <tr class="grey lighten-2">
            <td>Name</td>
            <td>Key</td>
            <td>status</td>
            <td>error</td>
            <td>oid</td>
            <td>Actions</td>
          </tr>
          <tr v-for="(environment, index) in environments" :key="index">
            <td>{{environment.name}}</td>
            <td>{{environment.key}}</td>
            <td class="text-no-wrap">
              <v-icon v-if="environment.hasError" color="red">mdi-alert-decagram</v-icon>
              {{environment.status}}
            </td>
            <td>{{environment.error}}</td>
            <td>
              <span
                :class="{'green--text': environment.commitsBehind === 0, 'red--text': environment.commitsBehind > 0}"
                :title="`Commits behind: ${environment.commitsBehind === -1 ? '?' : environment.commitsBehind}`"
              >
                {{ (environment.oid || '').slice(-10)}}
                <sup v-if="environment.commitsBehind > 0">{{environment.commitsBehind}}</sup>
              </span>
            </td>
            <td>
              <v-btn
                text
                v-if="!environment.promote && !environment.autoDeploys"
                :loading="!!loaders[`${environment.key}_deploy`]"
                @click="run(environment, 'deploy')"
              >
                Deploy
                <v-icon
                  right
                >
                  mdi-cloud-upload
                </v-icon>
              </v-btn>
              <v-btn
                text
                v-else-if="environment.promote"
                :loading="!!loaders[`${environment.key}_promote`]"
                @click="run(environment, 'promote')"
              >
                Promote
                <v-icon
                  right
                >
                  mdi-skip-next
                </v-icon>
              </v-btn>
              <v-btn
                text
                v-else-if="environment.autoDeploys"
                :loading="!!loaders[`${environment.key}_check`]"
                @click="run(environment, 'checkStatus')"
              >
                Check Status
                <v-icon
                  right
                >
                  mdi-skip-next
                </v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </c-form-box>
  </div>
</template>

<style lang="scss">
</style>

<script>
  import http from '@/@libs/http';
  import bus from '@/@libs/bus';

  export default {
    name: 'ServiceDetails',
    components: {
    },
    props: {
      service: Object,
    },
    data() {
      return {
        loaders: {},
      };
    },
    computed: {
      environments() {
        return this.service.environments.map((environment) => {
          const gitProvider = this.service.gitProvider;
          const commits = gitProvider && (gitProvider.commits || []);
          const commitsBehind = commits.findIndex((commit) => commit.oid === environment.oid);

          return {
            ...environment,
            commitsBehind,
          };
        });
      },
    },
    methods: {
      async run({ key }, action) {
        // make generic run endpoint and pass in
        try {
          this.loaders = {
            ...this.loaders,
            [`${key}_${action}`]: true,
          };
          await http.post(`/project/${this.service.projectId}/services/${this.service.id}/run`, {
            body: {
              key,
              action,
            },
          });
          this.$emit('saved');
        } finally {
          this.loaders[`${key}_${action}`] = false;
        }
      },
    },
  };
</script>
