<template>
  <v-dialog
    persistent
    v-model="showForm"
    scrollable
  >
    <v-card>
      <v-card-title class="headline" v-if="item">
        {{ create ? 'Create' : 'Update' }}
        Provider
      </v-card-title>

      <v-card-text v-if="item">
        <v-form
          ref="form"
          v-model="isValid"
          @submit.prevent="handleSubmit"
          id="provider-form"
        >
          <c-form-box>
            <v-text-field
              class="mb-2"
              v-model.trim="item.label"
              :rules="rules.label"
              type="text"
              label="Label"
            ></v-text-field>

            <v-select
              class="mb-2"
              v-model="item.type"
              :rules="rules.type"
              :items="providerTypesItems"
              label="Type"
            />

            <v-text-field
              class="mb-2"
              v-model.trim="item.auth.token"
              :rules="rules.token"
              type="text"
              label="Token"
            ></v-text-field>

            <v-text-field
              v-if="item.type === 'github'"
              class="mb-2"
              v-model.trim="item.auth.url"
              :rules="rules.token"
              type="text"
              label="Repository URL"
            ></v-text-field>

          </c-form-box>

        </v-form>
      </v-card-text>

      <v-card-actions class="pa-4">
        <div class="flex-grow-1"></div>

        <v-btn
          @click="showForm = false"
        >
          Cancel
        </v-btn>

        <v-btn
          type="submit"
          :loading="isLoading"
          :disabled="isLoading"
          color="primary"
          form="provider-form"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
</style>

<script>
  import omit from 'lodash/omit';
  import http from '@/@libs/http';
  import bus from '@/@libs/bus';
  import rules from '@/@libs/validations';

  export default {
    name: 'ProviderForm',
    props: {
      id: String,
      duplicateId: String,
      show: Boolean,
    },
    components: {
    },
    data() {
      return {
        originalItem: null,
        isValid: false,
        isLoading: false,
        item: {},
        rules: {
          label: [
            rules.required(),
          ],
          type: [
            rules.required(),
          ],
        },
      };
    },
    created() {
      this.setItem(this.id);
      this.loadData();
    },
    computed: {
      url() {
        return `/projects/${this.projectId}/providers`;
      },
      projectId() {
        return this.$route.params.projectId;
      },
      providerTypesItems() {
        return this.$store.getters['constants/providerTypesItems'];
      },
      showForm: {
        get() {
          return this.show;
        },
        set(value) {
          this.$emit('update:show', value);
        },
      },
      create() {
        const id = this.item && this.item.id;
        return !id;
      },
    },
    watch: {
      id: 'setItem',
      duplicateId: 'setItem',
    },
    methods: {
      async loadData() {
        // const [ProviderList] = await Promise.all([
        //   http.get(`/providers-list`),
        // ]);
        // this.ProviderList = ProviderList;
      },
      reset() {
        if (this.$refs.form) {
          this.$refs.form.reset();
        }
      },
      async setItem() {
        const id = this.id;
        if (this.duplicateId) {
          this.originalItem = null;
          this.item = null;
          // fetch item
          const originalItem = await http.get(`${this.url.replace(/\/$/, '')}/${this.duplicateId}`);
          this.originalItem = omit(originalItem, [
            'id',
            'created',
            'updated',
          ]);
        } else if (!id) {
          this.originalItem = {
            label: '',
            projectId: this.projectId,
            auth: {
              token: '',
            },
            commits: [],
          };
        } else {
          this.originalItem = null;
          this.item = null;
          // fetch item
          const originalItem = await http.get(`${this.url.replace(/\/$/, '')}/${id}`);
          this.originalItem = originalItem;
        }
        this.item = JSON.parse(JSON.stringify(this.originalItem));
      },
      async handleSubmit() {
        if (this.$refs.form.validate()) {
          this.isLoading = true;
          try {
            const item = this.item;
            // this.editingItem is the unchanged one
            const updates = http.getDiff(this.originalItem, item);
            if (this.create) {
              await http.post(this.url, {
                body: item,
              });
            } else {
              await http.put(`${this.url.replace(/\/$/, '')}/${this.item.id}`, {
                body: updates,
              });
            }

            this.$emit('saved', item);
            bus.trigger('alert', {
              type: 'success',
              message: 'Provider Successfully saved',
            });
            this.showForm = false;
          } finally {
            this.isLoading = false;
          }
        }
      },
    },

  };
</script>
