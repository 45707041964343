<template>
  <v-dialog
    persistent
    v-model="showForm"
    scrollable
  >
    <v-card>
      <v-card-title class="headline" v-if="item">
        {{ create ? 'Create' : 'Update' }}
        Service
      </v-card-title>

      <v-card-text v-if="item">
        <v-form
          ref="form"
          v-model="isValid"
          @submit.prevent="handleSubmit"
          id="service-form"
        >
          <c-form-box>
            <v-text-field
              class="mb-2"
              v-model.trim="item.label"
              :rules="rules.label"
              type="text"
              label="Label"
            ></v-text-field>
            <v-select
              class="mb-2"
              v-model="item.providerId"
              :rules="rules.providerId"
              :items="providersItems"
              label="Provider"
            />
            <v-select
              class="mb-2"
              v-model="item.status"
              :rules="rules.status"
              :items="serviceStatusesItems"
              label="Status"
            />
            <v-select
              class="mb-2"
              v-model="item.gitProviderId"
              :rules="rules.gitProviderId"
              :items="gitProvidersItems"
              label="Git Provider"
            />
          </c-form-box>
          <c-form-box title="environments">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr class="grey lighten-2">
                  <td>Name</td>
                  <td>Key</td>
                  <td v-if="isFirebase">Path</td>
                  <td>Promote</td>
                  <td>Auto Deploys</td>
                  <td width="70">
                    <v-btn
                      icon
                      @click="addEnvironment()"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr v-for="(environment, index) in item.environments" :key="index">
                  <td>
                    <v-text-field
                      class="mb-2"
                      v-model.trim="item.environments[index].name"
                      :rules="rules.environmentName"
                      type="text"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mb-2"
                      v-model.trim="item.environments[index].key"
                      :rules="rules.environmentKey"
                      type="text"
                    ></v-text-field>
                  </td>
                  <td v-if="isFirebase">
                    <v-text-field
                      class="mb-2"
                      v-model.trim="item.environments[index].path"
                      :rules="rules.path"
                      type="text"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-checkbox
                      class="mb-2"
                      v-model.trim="item.environments[index].promote"
                      type="text"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      class="mb-2"
                      v-model.trim="item.environments[index].autoDeploys"
                      type="text"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-icon
                      small
                      class="ma-1"
                      @click="removeEnvironment(index)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </c-form-box>

        </v-form>
      </v-card-text>

      <v-card-actions class="pa-4">
        <div class="flex-grow-1"></div>

        <v-btn
          @click="showForm = false"
        >
          Cancel
        </v-btn>

        <v-btn
          type="submit"
          :loading="isLoading"
          :disabled="isLoading"
          color="primary"
          form="service-form"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
</style>

<script>
  import omit from 'lodash/omit';
  import http from '@/@libs/http';
  import bus from '@/@libs/bus';
  import rules from '@/@libs/validations';

  export default {
    name: 'ServiceForm',
    props: {
      id: String,
      duplicateId: String,
      show: Boolean,
      providers: Array,
    },
    components: {
    },
    data() {
      return {
        originalItem: null,
        isValid: false,
        isLoading: false,
        item: {},
        rules: {
          label: [
            rules.required(),
          ],
          providerId: [
            rules.required(),
          ],
          gitProviderId: [
            rules.required(),
          ],
          environmentName: [
            rules.required(),
          ],
          environmentKey: [
            rules.required(),
          ],
        },
      };
    },
    created() {
      this.setItem(this.id);
      this.loadData();
    },
    computed: {
      isFirebase() {
        const provider = this.providers.find((p) => p.id === this.item.providerId);
        return provider && provider.type === 'firebase';
      },
      url() {
        return `/projects/${this.projectId}/services`;
      },
      serviceStatusesItems() {
        return this.$store.getters['constants/serviceStatusesItems'];
      },
      projectId() {
        return this.$route.params.projectId;
      },
      providersItems() {
        return this.providers
          .filter((p) => p.type !== 'github')
          .map((provider) => ({
            text: provider.label,
            value: provider.id,
          }));
      },
      gitProvidersItems() {
        return this.providers
          .filter((p) => p.type === 'github')
          .map((provider) => ({
            text: provider.label,
            value: provider.id,
          }));
      },
      // xxxItems() {
      //   return this.$store.getters['constants/xxxItems'];
      // },
      showForm: {
        get() {
          return this.show;
        },
        set(value) {
          this.$emit('update:show', value);
        },
      },
      create() {
        const id = this.item && this.item.id;
        return !id;
      },
    },
    watch: {
      id: 'setItem',
      duplicateId: 'setItem',
    },
    methods: {
      async loadData() {
        // const [ServiceList] = await Promise.all([
        //   http.get(`/services-list`),
        // ]);
        // this.ServiceList = ServiceList;
      },
      addEnvironment() {
        this.item.environments.push({
          key: '',
          name: '',
        });
      },
      removeEnvironment(index) {
        this.item.releaseSorting.splice(index, 1);
      },
      reset() {
        if (this.$refs.form) {
          this.$refs.form.reset();
        }
      },
      async setItem() {
        const id = this.id;
        if (this.duplicateId) {
          this.originalItem = null;
          this.item = null;
          // fetch item
          const originalItem = await http.get(`${this.url.replace(/\/$/, '')}/${this.duplicateId}`);
          this.originalItem = omit(originalItem, [
            'id',
            'created',
            'updated',
          ]);
        } else if (!id) {
          this.originalItem = {
            label: '',
            projectId: this.projectId,
            status: 'new',
            environments: [],
          };
        } else {
          this.originalItem = null;
          this.item = null;
          // fetch item
          const originalItem = await http.get(`${this.url.replace(/\/$/, '')}/${id}`);
          this.originalItem = originalItem;
        }
        this.item = JSON.parse(JSON.stringify(this.originalItem));
      },
      async handleSubmit() {
        if (this.$refs.form.validate()) {
          this.isLoading = true;
          try {
            const item = this.item;
            // this.editingItem is the unchanged one
            const updates = http.getDiff(this.originalItem, item);
            if (this.create) {
              await http.post(this.url, {
                body: item,
              });
            } else {
              await http.put(`${this.url.replace(/\/$/, '')}/${this.item.id}`, {
                body: updates,
              });
            }

            this.$emit('saved', item);
            bus.trigger('alert', {
              type: 'success',
              message: 'Service Successfully saved',
            });
            this.showForm = false;
          } finally {
            this.isLoading = false;
          }
        }
      },
    },

  };
</script>
