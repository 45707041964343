<template>
  <div>
    <c-toolbar
      :title="`Project: ${(project && project.label) || ''}`"
    />
    <v-row>
      <v-col>
        <c-form-box title="Providers" class="ma-3">
          <ProvidersTable
            :providers.sync="providers"
          />
        </c-form-box>
      </v-col>
      <v-col>
        <c-form-box title="Services" class="ma-3">
          <ServicesTable
            :providers="providers"
          />
        </c-form-box>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
</style>

<script>
  import http from '@/@libs/http';
  import ProvidersTable from './ProvidersTable';
  import ServicesTable from './ServicesTable';

  export default {
    name: 'Project',
    components: {
      ProvidersTable,
      ServicesTable,
    },
    props: {},
    created() {
      this.loadData();
    },
    data() {
      return {
        providers: [],
        project: null,
      };
    },
    computed: {
      projectId() {
        return this.$route.params.projectId;
      },
    },
    methods: {
      async loadData() {
        const [project] = await Promise.all([
          http.get(`/projects/${this.projectId}`),
        ]);
        this.project = project;
      },
    },
  };
</script>
