<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <c-form
    :minContentHeight="350"
    ref="form"
    :url="url"
    :form="form"
    :editingItem="user"
    showCancel
    @saved="onSaved"
    @cancel="onCancel"
    @change="onChange"
    id="dialog-form"
  ></c-form>
</template>

<script>
  import rules from '@/@libs/validations';

  export default {
    name: 'Profile',
    props: {
    },
    data() {
      return {
        url: '/users',
      };
    },
    computed: {
      user() {
        return this.$store.state.users.user;
      },
      form() {
        return {
          name: 'User',
          defaultValue: {
            multiFactorActive: false,
            role: null,
          },
          fields: [
            {
              label: 'email',
              name: 'username',
              type: 'text',
              rules: [
                rules.required(),
                rules.email(),
              ],
            },
            {
              label: 'First Name',
              name: 'firstName',
              type: 'text',
              rules: [
                rules.required(),
              ],
            },
            {
              label: 'Last Name',
              name: 'lastName',
              type: 'text',
              rules: [
                rules.required(),
              ],
            },
          ],
        };
      },
    },
    methods: {
      onChange(item) {
        this.item = item;
      },
      onSaved(user) {
        this.$emit('saved', user);
        this.$store.commit('users/setUser', user);
      },
      onCancel() {
        this.$emit('cancel');
      },
    },
  };
</script>
