<template>
  <div>
    <c-form-box title="commits">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
          <tr class="grey lighten-2">
            <td>oid</td>
            <td>message</td>
            <td>author</td>
            <td>Date</td>
          </tr>
          <tr v-for="(commit, index) in provider.commits" :key="index">
            <td>{{ (commit.oid || '').slice(-10)}}</td>
            <td>{{commit.message}}</td>
            <td>{{commit.author}}</td>
            <td>{{(new Date(commit.authorTimestamp)).toLocaleString()}}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </c-form-box>
  </div>
</template>

<style lang="scss">
</style>

<script>

  export default {
    name: 'ProviderDetails',
    components: {
    },
    props: {
      provider: Object,
    },
    data() {
      return {
      };
    },
    computed: {
    },
    methods: {
    },
  };
</script>
