<template>
  <div id="errorAlertsContainer">
    <v-snackbar
      v-if="message"
      :value="showSnackbar"
      :color="type"
      multi-line
      :timeout="-1"
    >
      <div
        style="padding-right: 85px;"
      >
        <v-badge
          color="orange"
          left
          overlap
          :value="alerts.length > 1"
        >
          <template v-slot:badge>
            {{alerts.length}}
          </template>
          <v-icon
            color="white"
            large
          >
            mdi-alert-circle
          </v-icon>
        </v-badge>
        <span
          class="ml-2"
          v-html="message"
        >
        </span>
        <v-btn
          class="alert-close-btn"
          dark
          text
          @click="close"
        >
          Close
        </v-btn>
      </div>
    </v-snackbar>

  </div>
</template>

<style lang="scss" scoped>
  .alert-close-btn {
    position: absolute;
    right: 10px;
    top: 16px;
  }
</style>

<script>
  import bus from '@/@libs/bus';

  export default {
    name: 'errorAlerts',
    data() {
      return {
        alerts: [],
      };
    },
    components: {
    },
    computed: {
      showSnackbar() {
        return this.alerts.length > 0;
      },
      message() {
        return this.alerts.length && this.alerts[this.alerts.length - 1].message;
      },
      type() {
        return this.alerts.length ? this.alerts[this.alerts.length - 1].type : 'info';
      },
    },
    methods: {
      handleAlert({ type = 'info', message, persist }) {
        if (this.alerts.length > 4) {
          this.alerts.shift();
        }
        const id = Math.random();
        this.alerts.push({
          id,
          type,
          message,
        });
        // if (type !== 'error' && persist !== true) {
        if (persist !== true) {
          setTimeout(() => {
            this.alerts = this.alerts.filter((alert) => alert.id !== id);
          }, type !== 'error' ? 3000 : 15000);
        }
      },
      handleError({ error }) {
        if (error.silent) {
          return;
        }
        let { message } = error;
        if (error.data && error.data.errorData && error.data.errorData.html) {
          message = error.data.errorData.html;
        } else if (error.data && error.data.errorMessage) {
          message = error.data.errorMessage;
        }
        this.handleAlert({
          message,
          type: 'error',
        });
      },
      close() {
        this.alerts.pop();
      },
    },
    mounted() {
      bus.on('error', this.handleError);
      bus.on('alert', this.handleAlert);
    },
    beforeDestroy() {
      bus.off('error', this.handleError);
      bus.off('alert', this.handleAlert);
    },
  };
</script>
