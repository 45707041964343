import jwtDecode from 'jwt-decode';

const auth = {
  isAuthorized({ permission, dispatch }) {
    // todo: if no token then notAuthorized
    // todo: if token but no access then forbidden (not doing this for boilerplate
    const allowed = this.getToken();

    if (allowed) {
      return true;
    }

    return false;
  },

  getToken() {
    return localStorage.getItem('token');
  },

  setToken(token) {
    return localStorage.setItem('token', token);
  },

  removeToken() {
    return localStorage.removeItem('token');
  },

  getPayload() {
    const token = this.getToken();
    if (token) {
      const payload = jwtDecode(token);
      return payload;
    }
    return null;
  },

  getUserType() {
    const payload = this.getPayload();
    return payload ? payload.type : null;
  },

  isType(types) {
    const type = this.getUserType();
    if (!type) {
      return false;
    }
    if (Array.isArray(types)) {
      return types.indexOf(type) > -1;
    }
    return types === type;
  },
};

export default auth;
