<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-card>
    <v-card-title class="headline">
      {{create ? 'Create' : 'Update'}}
      {{form.name}}
    </v-card-title>

    <v-card-text :style="`min-height: ${minContentHeightStyle}`">
      <v-form
        ref="form"
        v-model="isValid"
        @submit.prevent="handleSubmit"
        :id="id"
      >
        <c-form-fields
          :fields="fields"
          :item="item"
        />
      </v-form>
    </v-card-text>

    <v-card-actions class="pa-4">
      <div class="flex-grow-1"></div>

      <v-btn
        v-if="showCancel"
        @click="$emit('cancel')"
      >
        Cancel
      </v-btn>

      <v-btn
        type="submit"
        :loading="isLoading"
        :disabled="isLoading"
        color="primary"
        :form="id"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import http from '@/@libs/http';
  import bus from '@/@libs/bus';

  export default {
    name: 'Form',
    props: {
      showCancel: Boolean,
      id: String,
      minContentHeight: Number,
      url: String,
      form: Object,
      editingItem: Object,
      onSubmit: Function,
      // loading: Boolean,
    },
    data() {
      return {
        isLoading: false,
        isValid: false,
        item: {},
      };
    },
    created() {
      this.setItem(this.editingItem);
    },
    watch: {
      item: {
        handler(item) {
          this.$emit('change', item);
        },
        deep: true,
      },
      // show(show) {
      //   if (show) {
      //     this.setItem(this.editingItem);
      //   }
      //   if (this.$refs.form) {
      //     this.$refs.form.resetValidation();
      //   }
      // },
      editingItem: {
        handler: 'setItem',
        deep: true,
      },
    },
    computed: {
      // isLoading: {
      //   get() {
      //     return this.loading;
      //   },
      //   set(value) {
      //     this.$emit('update:loading', value);
      //   },
      // },
      minContentHeightStyle() {
        const height = this.minContentHeight;
        return height ? `${height}px` : 'auto';
      },
      fields() {
        return this.form.fields.filter((field) => {
          const type = this.create ? 'create' : 'update';
          return !(field.on && field.on !== type);
        });
      },
      create() {
        const id = this.item && this.item.id;
        return !id;
      },
    },
    methods: {
      reset() {
        if (this.$refs.form) {
          this.$refs.form.reset();
        }
      },
      setItem(item = {}) {
        let value = JSON.parse(JSON.stringify(item)) || {};
        if (this.form.in) {
          value = this.form.in(value);
        }
        this.item = value;
      },
      async handleSubmit() {
        if (this.$refs.form.validate()) {
          this.isLoading = true;
          try {
            let item = this.item;
            if (this.form.out) {
              item = this.form.out(item);
            }
            // this.editingItem is the unchanged one
            const updates = http.getDiff(this.editingItem, item);
            let result;
            if (this.onSubmit) {
              result = await this.onSubmit({
                create: this.create,
                item,
                updates,
              });
            } else {
              if (this.create) {
                result = await http.post(this.url, {
                  body: item,
                });
                // todo: send success notification
              } else {
                result = await http.put(`${this.url.replace(/\/$/, '')}/${this.item.id}`, {
                  body: updates,
                });
              }
            }

            // we arent using this with sync on most(all?) forms. When we save the editingItem must
            // be updated by either refetching the data with @saved or editingItem.sync otherwise
            // if we resave something like a toggle it will fail to see the diff the second time
            this.$emit('update:editingItem', result || item);

            this.$emit('saved', item);
            bus.trigger('alert', {
              type: 'success',
              message: `${this.form.name} Successfully saved`,
            });
          } finally {
            this.isLoading = false;
          }
        }
      },
    },
  };
</script>
