<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-tooltip bottom v-if="show">
    <template
      v-slot:activator="{ on }"
    >
      <v-btn
        v-if="type === 'button'"
        v-on="on"
        small
        :color="$_color"
        class="ma-1"
        @click="emit ? $emit(emit, item) : onClick(item, action)"
      >
        {{text}}
        <v-icon
          right
          v-if="icon"
          small
        >
          {{icon}}
        </v-icon>
      </v-btn>
      <v-icon
        :color="$_color"
        v-else
        v-on="on"
        small
        class="ma-1"
        @click="emit ? $emit(emit, item) : onClick(item, action)"
      >
        {{icon}}
      </v-icon>
    </template>
    <span>{{text}}</span>
  </v-tooltip>
</template>

<script>
  export default {
    name: 'ColumnAction',
    props: {
      color: [String, Function],
      type: String,
      emit: String,
      onClick: Function,
      icon: String,
      text: String,
      item: Object,
      show: Boolean,
    },
    computed: {
      $_color() {
        if (typeof this.color === 'function') {
          return this.color(this.item);
        }
        return this.color;
      },
      action() {
        return {
          emit: this.emit,
          onClick: this.onClick,
          icon: this.icon,
          text: this.text,
        };
      },
    },
  };
</script>
