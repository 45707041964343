<template>
  <div id="app">
    <component :is="layout">
      <Confirm />
      <Alerts />
      <ModalAlerts />
      <router-view/>
    </component>
  </div>
</template>

<style lang="scss">
body {
  /*margin: 0;*/
  padding: 0;
}
</style>

<script>
  import bus from '@/@libs/bus';
  import './@layouts';
  import Alerts from '@/@components/Alerts';
  import Confirm from '@/@components/Confirm';
  import ModalAlerts from '@/@components/ModalAlerts';

  export default {
    name: 'app',
    components: {
      Alerts,
      Confirm,
      ModalAlerts,
    },
    async created() {},
    mounted() {
      bus.on('error', this.handleError);
    },
    beforeDestroy() {
      bus.off('error', this.handleError);
    },
    computed: {
      layout() {
        // do not want to set a default layout because we do get a route at first that is a "fake"
        // route because of the await in the beforeRoute and that will just cause the default
        // route to be rendered briefly even if it's wrong. (portal-ui is implemented wrong)
        // so to overcome this issue we just force a layout to be configured in the routes.
        return this.$route.meta.layout;
      },
    },
    methods: {
      async handleError({ error }) {
        if (error.code === 'HTTP_NOT_AUTHORIZED') {
          if (this.$route.name !== 'login') {
            await this.$router.replace({ name: 'login' }).catch((err) => {
              console.error(err);
            }); // routing to same route throws error, catching to ignore that
          }
        }
      },
    },
  };
</script>
