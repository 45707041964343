import get from 'lodash/get';

export default {
  filter(data, filters) {
    return data.filter((item) => {
      // do a for loop so that as soon as one fails we can return false
      for (let i = 0; i < filters.length; i += 1) {
        const { value, type, column } = filters[i];
        // const itemValue = item[column];
        const itemValue = get(item, column);
        if (type === '$eq') {
          if (itemValue !== value) {
            return false;
          }
        } else if (type === '$contains') {
          if (itemValue.indexOf(value) === -1) {
            return false;
          }
        } else if (type === '$gt') {
          if (itemValue <= value) {
            return false;
          }
        } else if (type === '$gte') {
          if (itemValue < value) {
            return false;
          }
        } else if (type === '$lt') {
          if (itemValue >= value) {
            return false;
          }
        } else if (type === '$lte') {
          if (itemValue > value) {
            return false;
          }
        } else if (type === '$in') {
          if (value.indexOf(itemValue) === -1) {
            return false;
          }
        } else {
          throw new Error(`We do not support filter of type: ${type}`);
        }
      }
      return true;
    });
  },
};
