<template>
  <div class="services-table">
    <c-data-table
      ref="table"
      id="generic-table"
      uid="id"
      search
      :selectedFilters.sync="selectedFilters"
      :url="url"
      :columns="columns"
      :filters="filters"
      :actions="actions"
      :items.sync="items"
      load-all
    >
      <template v-slot:item.xxx="{ item }">
        template
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <div class="pa-3">
            <ServiceDetails
              :service="item"
              @saved="onSaved"
            />
          </div>
        </td>
      </template>
    </c-data-table>

    <ServicesForm
      v-if="showForm"
      :show.sync="showForm"
      :providers="providers"
      :duplicateId="duplicateId"
      :id="editingId"
      :url="url"
      @saved="onSaved"
    ></ServicesForm>
  </div>
</template>

<style lang="scss">
</style>

<script>
  import http from '@/@libs/http';
  import bus from '@/@libs/bus';
  import ServicesForm from './ServicesForm';
  import ServiceDetails from './ServiceDetails';

  export default {
    name: 'ServicesTable',
    props: {
      providers: Array,
    },
    components: {
      ServicesForm,
      ServiceDetails,
    },
    created() {
      this.intervalId = setInterval(() => {
        this.updateIfProcessing();
      }, 5000);
      this.loadData();
      bus.on('load-services', this.onSaved);
    },
    beforeDestroy() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      bus.off('load-services', this.onSaved);
    },
    data() {
      return {
        showForm: false,
        editingId: null,
        duplicateId: null,
        selectedFilters: [],
        items: [],
        columns: [
          {
            id: 'expand',
            text: '',
            type: 'expand',
            sortable: false,
            default: true,
          },
          {
            id: 'id',
            text: 'id',
            sortable: false,
            value: 'id',
            default: false,
          },
          {
            id: 'label',
            text: 'Label',
            sortable: false,
            value: 'label',
            default: true,
          },
          {
            id: 'status',
            text: 'Status',
            sortable: false,
            value: 'status',
            default: true,
          },
          {
            id: 'providerId',
            text: 'Provider',
            sortable: false,
            value: 'provider.label',
            default: true,
          },
          {
            id: 'gitProviderId',
            text: 'Git Provider',
            sortable: false,
            value: 'gitProvider.label',
            default: true,
          },
          {
            id: 'created',
            text: 'Created',
            value: 'created',
            type: 'date',
            sortable: true,
            default: true,
          },
          {
            id: 'updated',
            text: 'Updated',
            value: 'updated',
            type: 'date',
            sortable: true,
          },
          {
            id: 'actions',
            text: 'Actions',
            align: 'left',
            sortable: false,
            default: true,
            width: 120,
            actions: [
              {
                text: 'Edit',
                icon: 'mdi-pencil',
                onClick: (item) => {
                  this.duplicateId = null;
                  this.editingId = item.id;
                  this.showForm = true;
                },
              },
              {
                text: 'Delete',
                icon: 'mdi-delete',
                emit: 'delete',
                // onClick: () => {},
              },
            ],
          },
        ],
        actions: [
          {
            text: 'Create',
            icon: 'mdi-plus',
            // emit: 'create',
            onClick: () => {
              this.showForm = true;
              this.editingId = null;
            },
          },
        ],
      };
    },
    computed: {
      url() {
        return `/projects/${this.projectId}/services`;
      },
      projectId() {
        return this.$route.params.projectId;
      },
      filters() {
        return [
          // {
          //   type: '$eq',
          //   column: 'marketplace.vendorId',
          //   id: 'vendorId',
          //   field: {
          //     label: 'Vendor',
          //     type: 'autocomplete',
          //     items: this.vendorsList,
          //   },
          // },
        ];
      },
    },
    methods: {
      async updateIfProcessing() {
        const isProcessing = this.items.some((item) => item.status === 'processing');
        if (isProcessing) {
          await Promise.all([
            this.loadData(),
            this.onSaved(),
          ]);
        }
      },
      async loadData() {
        // const [vendorsList] = await Promise.all([
        //   http.get(`/vendors/list`),
        // ]);
        // this.vendorsList = vendorsList;
      },
      onSaved() {
        this.$refs.table.fetchData();
      },
    },
  };
</script>
