import http from '../../@libs/http';

const constantsModule = {
  namespaced: true,
  state: {
    status: 'empty',
    roles: null,
    providerTypes: null,
    serviceStatuses: null,
  },
  // mutations, actions, getters...
  getters: {
    rolesItems({ roles }) {
      if (!roles) {
        return [];
      }
      return Object.values(roles);
    },
    serviceStatusesItems({ serviceStatuses }) {
      if (!serviceStatuses) {
        return [];
      }
      return Object.values(serviceStatuses);
    },
    providerTypesItems({ providerTypes }) {
      if (!providerTypes) {
        return [];
      }
      return Object.values(providerTypes);
    },
  },
  mutations: {
    setConstants(state, constants) {
      const {
        roles,
        serviceStatuses,
        providerTypes,
      } = constants;
      state.roles = roles;
      state.serviceStatuses = serviceStatuses;
      state.providerTypes = providerTypes;
    },
  },
  actions: {
    async fetchConstants({ commit }) {
      if (this.status !== 'empty') {
        this.status = 'loading';
        const constants = await http.get('/constants');
        commit('setConstants', constants);
        this.status = 'loaded';
      }
    },
  },
};

export default constantsModule;
