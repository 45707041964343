<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <div class="pa-2" v-if="chips && chips.length">
      <v-chip
        :class="{fixed: chip.fixed}"
        v-for="(chip, index) in chips"
        :key="index"
        class="ma-1"
        :close="!chip.fixed"
        @click:close="removeFilter(index)"
      >
        <span class="mr-1" v-if="chip.label">({{chip.label}})</span>
        <strong v-html="chip.value"></strong>&nbsp;
      </v-chip>
    </div>
    <v-dialog
      v-model="$_show"
      scrollable
      max-width="500"
    >
      <v-card>
        <v-card-title class="headline">
          Filters
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            v-model="isValid"
            @submit.prevent="handleSubmit"
            :id="formId"
          >
            <c-form-fields
              :fields="fields"
              :item="item"
            />
          </v-form>
        </v-card-text>

        <v-card-actions class="pa-4">
          <div class="flex-grow-1"></div>

          <v-btn
            @click="$_show = false"
          >
            Cancel
          </v-btn>

          <v-btn
            type="submit"
            color="primary"
            :form="formId"
          >
            Apply Filters
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
  .v-chip.fixed {
    background: #aaa !important;
  }
</style>

<script>
  import keyBy from 'lodash/keyBy';

  export default {
    name: 'Filters',
    props: {
      id: String,
      selectedFilters: Array,
      filters: Array,
      show: Boolean,
    },
    data() {
      const item = {};
      this.filters.forEach((filter) => {
        if (!filter.id) {
          throw new Error('Filter id is a required property');
        }
        item[filter.id] = null;
      });
      return {
        isValid: false,
        item,
      };
    },
    watch: {
      show() {
        if (this.$refs.form) {
          // this.$refs.form.reset();
        }
        this.filters.forEach((filter) => {
          this.item[filter.id] = null;
        });
        this.selectedFilters.forEach((selectedFilter) => {
          this.item[selectedFilter.id] = selectedFilter.value;
        });
      },
    },
    computed: {
      formId() {
        return `${this.id}${this._uid}filter-form`;
      },
      chips() {
        const filterMap = keyBy(this.filters, 'id');
        return this.selectedFilters.map((selectedFilter) => {
          const field = filterMap[selectedFilter.id].field;
          let value = selectedFilter.value;
          let label = field.label;
          if (field.items && field.items.length) {
            const item = field.items.find((_item) => _item.value === selectedFilter.value);
            if (item && item.text) {
              value = item.text;
            }
          }
          // convert only datetime type date not time only or date only
          if (field.type === 'date' && ['time', 'date'].indexOf(field.dateType) === -1) {
            value = value ? (new Date(value)).toLocaleString() : '';
          }
          if (field.type === 'checkbox') {
            value = label;
            label = null;
          }
          if (Array.isArray(value)) {
            value = value
              .map((itemValue) => {
                if (field.items && field.items.length) {
                  const item = field.items.find((_item) => _item.value === itemValue);
                  if (item && item.text) {
                    return item.text;
                  }
                }
                return itemValue;
              })
              .join(', ');
          }
          return {
            fixed: selectedFilter.fixed,
            label,
            value,
          };
        });
      },
      $_show: {
        get() {
          return this.show;
        },
        set(value) {
          this.$emit('update:show', value);
        },
      },
      fields() {
        return this.filters.map(filter => ({
          ...filter.field,
          name: filter.id,
          clearable: true,
        }));
      },
    },
    methods: {
      removeFilter(index) {
        const updatedFilters = this.selectedFilters.filter((item, i) => i !== index);
        this.$emit('update:selectedFilters', updatedFilters);
      },
      handleSubmit() {
        const selectedFilters = [];

        this.filters.forEach((filter) => {
          const value = this.item[filter.id];
          const emtpy = value === null || typeof value === 'undefined' || value === '';
          // we could have a false value that is not falsy but in such a scenario we would not want
          // falseCheckbox to be true even if it was false since we must want to send that value
          // so just make sure falseValue to be a falsy
          const falseCheckbox = filter.input && filter.field.type === 'checkbox' && !value;
          if (!emtpy && !falseCheckbox) {
            selectedFilters.push({
              value: this.item[filter.id],
              type: filter.type,
              column: filter.column,
              id: filter.id,
            });
          }
        });
        this.$emit('update:selectedFilters', selectedFilters);
        this.$_show = false;
      },
    },
  };
</script>
