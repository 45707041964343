<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-card tile class="mb-5" :color="color">
    <v-card-text>
      <div class="overline pb-2" :class="{ expandable }" v-if="title || expandable" @click="toggle()">
        <v-icon v-if="icon" class="mr-1" :color="iconColor">{{icon}}</v-icon>
        <template v-else-if="expandable">
          <v-icon>
            mdi-chevron-{{$_isExpanded ? 'up' : 'down'}}
          </v-icon>
        </template>
        <span :class="titleClass">{{title}}</span>
      </div>
      <slot v-if="$_isExpanded"></slot>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
  .expandable {
    cursor: pointer;
  }
</style>

<script>
  export default {
    name: 'FormBox',
    props: {
      title: String,
      color: String,
      icon: String,
      iconColor: String,
      titleClass: String,
      expandable: Boolean,
      expanded: Boolean,
      isExpanded: {
        type: Boolean,
        default: null,
      },
    },
    data() {
      return {
        localIsExpanded: false,
      };
    },
    created() {
      this.$_isExpanded = this.expanded;
    },
    computed: {
      $_isExpanded: {
        get() {
          if (!this.expandable) {
            return true;
          }
          return typeof this.isExpanded === 'boolean' ? this.isExpanded : this.localIsExpanded;
        },
        set(value) {
          if (typeof this.isExpanded === 'boolean') {
            this.$emit('update:isExpanded', value);
          } else {
            this.localIsExpanded = value;
          }
        },
      },
    },
    methods: {
      toggle() {
        if (this.expandable) {
          this.$_isExpanded = !this.$_isExpanded;
        }
      },
    },
  };
</script>
