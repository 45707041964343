
const wrap = (func) => (value) => {
  // if we have no value we don't validate (add required validator for that)
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return func(value);
};

// fixme: some of these rules like numeric and integer only work for string numbers
const regexRules = {
  numeric: {
    validator: /^[0-9]+$/,
    message: 'Not a valid number',
  },
  integer: {
    validator: /^-?[0-9]+$/,
    message: 'Not a valid Integer',
  },
  decimal: {
    validator: /^-?[0-9]*\.?[0-9]+$/,
    message: 'Not a valid decimal',
  },
  email: {
    validator: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
    message: 'Not a valid email',
  },
  proxy: {
    validator: /^http:\/\/([^:]+):([^@]+)@([^:]+):([0-9]+)$/i,
    message: 'Not a valid Proxy Url',
  },
  alpha: {
    validator: /^[a-z]+$/i,
    message: 'Not a valid alpha',
  },
  alphaNumeric: {
    validator: /^[a-z0-9]+$/i,
    message: 'Not a valid alpha numeric value',
  },
  alphaDash: {
    validator: /^[a-z0-9_-]+$/i,
    message: 'Not a valid alpha numeric dash value',
  },
  natural: {
    validator: /^[0-9]+$/i,
    message: 'Not a valid natural number',
  },
  naturalNoZero: {
    validator: /^[1-9][0-9]*$/i,
    message: 'Not a valid natural value',
  },
  ip: {
    validator: /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})$/i,
    message: 'Not a valid ip address',
  },
  base64: {
    validator: /[^a-zA-Z0-9/+=]/i,
    message: 'Not a valid base 64',
  },
  numericDash: {
    validator: /^[\d\-\s]+$/,
    message: 'Not a valid numeric dash',
  },
  url: {
    validator: /^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?$/,
    message: 'Not a valid url',
  },
  phoneUS: {
    validator: /^(1-?)?(\([0-9]\d{2}\)|[0-9]\d{2})[- ]?[0-9]\d{2}[- ]?\d{4}$/,
    message: 'Not a valid phone number',
  },
  zip: {
    validator: /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$)/,
    message: 'Not a valid zip code',
  },
  // this is for usa and canada zip codes
  zipUSCA: {
    validator: /(^([0-9]{5})$)|(^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$)/,
    message: 'Not a valid zip code',
  },
};

function regexRuleConvert(key, customMessage) {
  const rule = regexRules[key];
  const msg = customMessage || rule.message;
  const validator = (val) => {
    return val.match(rule.validator) ? true : msg;
  };
  return wrap(validator);
}

const rules = {
  required(customMessage) {
    const msg = customMessage || 'Field is required';
    return (val) => (!!val || val === 0 || val === false) || msg;
  },
  // not doing them dynamically for better autocomplete
  proxy: (customMessage) => regexRuleConvert('proxy', customMessage),
  email: (customMessage) => regexRuleConvert('email', customMessage),
  url: (customMessage) => regexRuleConvert('url', customMessage),
  alpha: (customMessage) => regexRuleConvert('alpha', customMessage),
  alphaNumeric: (customMessage) => regexRuleConvert('alphaNumeric', customMessage),
  alphaDash: (customMessage) => regexRuleConvert('alphaDash', customMessage),
  ip: (customMessage) => regexRuleConvert('ip', customMessage),
  numericDash: (customMessage) => regexRuleConvert('numericDash', customMessage),
  phoneUS: (customMessage) => regexRuleConvert('phoneUS', customMessage),
  zip: (customMessage) => regexRuleConvert('zip', customMessage),
  zipUSCA: (customMessage) => regexRuleConvert('zipUSCA', customMessage),
  min(totalCharacters, customMessage) {
    const msg = customMessage || `Field should be at least ${totalCharacters} long`;
    const validator = (val) => val.length >= totalCharacters || msg;
    return wrap(validator);
  },
  max(totalCharacters, customMessage) {
    const msg = customMessage || `Field should be at least ${totalCharacters} long`;
    const validator = (val) => val.length >= totalCharacters || msg;
    return wrap(validator);
  },
  codeRegex(regexString) {
    return (val) => {
      if (!regexString) {
        return true;
      }
      if (!val) {
        return true;
      }
      // this is a hack to avoid validating masked card values, not ideal because it allows
      // for inputting the wrong code.
      if (val.match(/^xxxx/i)) {
        return true;
      }
      if (!val.match(new RegExp(regexString))) {
        return 'Invalid Value';
      }
      return true;
    };
  },
};
//
// Object.keys(regexRules).forEach((key) => {
//   rules[key] = () => {
//     const rule = regexRules[key];
//     const msg = rule.message;
//     const validator = val => val.match(rule.validator) || msg;
//     return wrap(validator);
//   };
// });

export default rules;
