<template>
  <v-toolbar
    color="primary"
    dark
    dense
    tabs
  >
    <v-toolbar-title>{{title}}</v-toolbar-title>
    <v-spacer></v-spacer>

    <v-btn
      icon
      v-for="(action, index) in actions"
      :key="index"
      @click="action.click ? action.click : () => {}"
      :tag="action.tag"
      :to="action.to"
    >
      <v-icon>{{action.icon}}</v-icon>
    </v-btn>
    <template v-slot:extension v-if="tabs && tabs.length">
      <v-tabs
        color="white"
        background-color="transparent"
        :value="value"
        @change="$emit('input', $event)"
        :grow="grow"
        :right="right"
      >
        <v-tabs-slider color="white"></v-tabs-slider>

        <v-tab
          v-for="tabItem in tabs"
          :key="tabItem"
        >
          {{ tabItem }}
        </v-tab>
      </v-tabs>
    </template>

  </v-toolbar>
</template>

<style lang="scss">
</style>

<script>
  export default {
    name: 'Toolbar',
    props: {
      title: String,
      tabs: Array,
      actions: Array,
      value: Number,
      grow: {
        type: Boolean,
        default: true,
      },
      right: Boolean,
    },
  };
</script>
