<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-sheet>
    <v-tabs
      v-model="activeTab"
      background-color="transparent"
      grow
    >
      <v-tab>
        My Profile
      </v-tab>
      <v-tab>
        Change Password
      </v-tab>
      <v-tab>
        2 Factor Authentication
      </v-tab>
    </v-tabs>
    <div class="pa-2" style="min-height: 200px">
      <template v-if="activeTab === 0">
        <Profile
          @cancel="$emit('cancel')"
          @saved="$emit('saved')"
        ></Profile>
      </template>
      <template v-else-if="activeTab === 1">
        <Password
          @cancel="$emit('cancel')"
          @saved="$emit('saved')"
        />
      </template>
      <template v-else-if="activeTab === 2">
        <MultiFactor
          @cancel="$emit('cancel')"
          @saved="$emit('saved')"
        />
      </template>
    </div>
  </v-sheet>
</template>

<script>
  import Profile from './Profile';
  import Password from './Password';
  import MultiFactor from './MultiFactor';

  export default {
    name: 'UserProfile',
    components: {
      Profile,
      Password,
      MultiFactor,
    },
    props: {
      show: Boolean,
      tab: Number,
    },
    async created() {
      await this.$store.dispatch('users/loadUser');
    },
    data() {
      return {
      };
    },
    computed: {
      activeTab: {
        get() {
          return this.tab;
        },
        set(value) {
          this.$emit('update:tab', value);
        },
      },
    },
    methods: {
    },
  };
</script>
