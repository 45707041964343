<template>
  <v-card>
    <v-card-title class="headline">
      Setup 2 Factor Authentication
    </v-card-title>

    <v-card-text style="min-height: 350px">
      <v-alert
        v-model="multiFactorAdded"
        type="success"
        dismissible
      >
        Two Factor Authentication successfully added!
      </v-alert>
      <div v-if="!multiFactorAdded">
        <v-form
          ref="generateForm"
          key="generate-mfa"
          v-model="isValid"
          @submit.prevent="submitGenerate"
          id="generate-mfa"
          v-if="!qrCode"
        >
          <v-alert
            border="left"
            colored-border
            type="info"
            elevation="2"
          >
            Enter your password to generate a new 2 factor code and follow the directions in the
            next step.
            <div v-if="user.multiFactorActive">
              You already have 2FA setup on your account. When clicking on generate your <strong>old
              code will be deleted</strong>. You will need to enter your current mfa code to
              generate a new one.
            </div>
          </v-alert>
          <v-text-field
            class="mb-2"
            v-model="generateFormData.password"
            :rules="rules.password"
            type="password"
            label="Password"
          ></v-text-field>
          <v-text-field
            v-if="user.multiFactorActive"
            autocomplete="off"
            class="mb-2"
            v-model="generateFormData.multiFactorToken"
            :rules="rules.multiFactorToken"
            type="text"
            label="Current MFA Code"
          ></v-text-field>
        </v-form>
        <v-form
          ref="confirmForm"
          key="confirm-mfa"
          v-model="isValid"
          @submit.prevent="submitConfirm"
          id="confirm-mfa"
          v-else
        >
          <v-alert
            border="left"
            colored-border
            type="info"
            elevation="2"
          >
            Use your 2FA application of choice such as google authenticator or Authy and scan the QR
            Code. Then enter the authentiaction code to complete you 2FA Setup.

            <strong>
              If you skip this step, and previously had 2FA setup, your account is now without 2FA.
            </strong>
          </v-alert>
          <div class="pb-2">
            <img :src="qrCode" alt="secret">
          </div>
          <div class="pb-2">{{secret}}</div>
          <v-text-field
            autocomplete="off"
            class="mb-2"
            v-model="confirmFormData.multiFactorToken"
            :rules="rules.multiFactorToken"
            type="text"
            label="MFA Code"
          ></v-text-field>
        </v-form>
      </div>
    </v-card-text>

    <v-card-actions class="pa-4" v-if="!multiFactorAdded">
      <div class="flex-grow-1"></div>
      <v-btn
        @click="$emit('cancel')"
      >
        Cancel
      </v-btn>

      <v-btn
        v-if="!qrCode"
        type="submit"
        :loading="isLoading"
        :disabled="isLoading"
        color="primary"
        form="generate-mfa"
      >
        Generate
      </v-btn>
      <v-btn
        v-else
        type="submit"
        :loading="isLoading"
        :disabled="isLoading"
        color="primary"
        form="confirm-mfa"
      >
        Confirm MFA Code
      </v-btn>
    </v-card-actions>
    <v-card-actions class="pa-4" v-else>
      <div class="flex-grow-1"></div>
      <v-btn
        @click="$emit('cancel')"
      >
        CLose
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss">
</style>

<script>
  import http from '@/@libs/http';
  import bus from '@/@libs/bus';
  import validations from '@/@libs/validations';

  export default {
    name: 'MultiFactor',
    components: {
    },
    props: {},
    data() {
      return {
        qrCode: null,
        secret: null,
        isValid: false,
        isLoading: false,
        multiFactorAdded: false,
        generateFormData: {},
        confirmFormData: {},
        rules: {
          password: [validations.required()],
          multiFactorToken: [validations.required()],
        },
      };
    },
    computed: {
      user() {
        return this.$store.state.users.user;
      },
    },
    methods: {
      async submitGenerate() {
        if (this.$refs.generateForm.validate()) {
          this.isLoading = true;
          try {
            const result = await http.post(`/multifactor/generate/${this.user.id}`, {
              body: this.generateFormData,
            });
            this.qrCode = result.qrCode;
            this.secret = result.secret;
            await this.$store.dispatch('users/loadUser');
          } catch (error) {
            console.log(error);
            // handle 401 so it doesn't redirect us
            if (error.code === 'HTTP_NOT_AUTHORIZED') {
              bus.trigger('alert', {
                type: 'error',
                message: error.data.errorMessage,
              });
            } else {
              throw error;
            }
          } finally {
            this.isLoading = false;
          }
        }
      },
      async submitConfirm() {
        if (this.$refs.confirmForm.validate()) {
          this.isLoading = true;
          try {
            await http.post(`/multifactor/confirm/${this.user.id}`, {
              body: this.confirmFormData,
            });
            this.multiFactorAdded = true;
            this.qrCode = null;
            this.secret = null;
            await this.$store.dispatch('users/loadUser');
          } catch (error) {
            // handle 401 so it doesn't redirect us
            if (error.code === 'HTTP_NOT_AUTHORIZED') {
              bus.trigger('alert', {
                type: 'error',
                message: error.data.errorMessage,
              });
            } else {
              throw error;
            }
          } finally {
            this.isLoading = false;
          }
        }
      },
    },
  };
</script>
