<template>
  <div class="providers-table">
    <c-data-table
      ref="table"
      id="generic-table"
      uid="id"
      search
      :selectedFilters.sync="selectedFilters"
      :url="url"
      :columns="columns"
      :filters="filters"
      :actions="actions"
      :items.sync="items"
      :loading.sync="loading"
      load-all
    >
      <template v-slot:item.xxx="{ item }">
        template
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <div class="pa-3">
            <ProviderDetails
              :provider="item"
            />
          </div>
        </td>
      </template>
    </c-data-table>

    <ProvidersForm
      v-if="showForm"
      :show.sync="showForm"
      :duplicateId="duplicateId"
      :id="editingId"
      :url="url"
      @saved="onSaved"
    ></ProvidersForm>
  </div>
</template>

<style lang="scss">
</style>

<script>
  import http from '@/@libs/http';
  import bus from '@/@libs/bus';
  import ProvidersForm from './ProvidersForm';
  import ProviderDetails from './ProviderDetails';

  export default {
    name: 'ProvidersTable',
    props: {
      providers: Array,
    },
    components: {
      ProvidersForm,
      ProviderDetails,
    },
    created() {
      this.loadData();
    },
    data() {
      return {
        loading: false,
        showForm: false,
        editingId: null,
        duplicateId: null,
        selectedFilters: [],
        columns: [
          {
            id: 'expand',
            text: '',
            type: 'expand',
            sortable: false,
            default: true,
          },
          {
            id: 'id',
            text: 'id',
            sortable: false,
            value: 'id',
            default: false,
          },
          {
            id: 'label',
            text: 'Label',
            sortable: false,
            value: 'label',
            default: true,
          },
          {
            id: 'type',
            text: 'Type',
            sortable: false,
            value: 'type',
            default: true,
          },
          {
            id: 'auth.token',
            text: 'Auth Token',
            sortable: false,
            value: 'auth.token',
            default: false,
          },
          {
            id: 'created',
            text: 'Created',
            value: 'created',
            type: 'date',
            sortable: true,
            default: true,
          },
          {
            id: 'updated',
            text: 'Updated',
            value: 'updated',
            type: 'date',
            sortable: true,
          },
          {
            id: 'actions',
            text: 'Actions',
            align: 'left',
            sortable: false,
            default: true,
            width: 120,
            actions: [
              {
                text: 'Edit',
                icon: 'mdi-pencil',
                onClick: (item) => {
                  this.duplicateId = null;
                  this.editingId = item.id;
                  this.showForm = true;
                },
              },
              {
                text: 'Delete',
                icon: 'mdi-delete',
                emit: 'delete',
                // onClick: () => {},
              },
              {
                text: 'Fetch Latest Commits',
                icon: 'mdi-update',
                condition: (provider) => provider.type === 'github',
                onClick: (item) => {
                  this.getCommits(item);
                },
              },
            ],
          },
        ],
        actions: [
          {
            text: 'Create',
            icon: 'mdi-plus',
            // emit: 'create',
            onClick: () => {
              this.showForm = true;
              this.editingId = null;
            },
          },
        ],
      };
    },
    computed: {
      url() {
        return `/projects/${this.projectId}/providers`;
      },
      items: {
        get() {
          return this.providers;
        },
        set(items) {
          this.$emit('update:providers', items);
        },
      },
      projectId() {
        return this.$route.params.projectId;
      },
      filters() {
        return [
          // {
          //   type: '$eq',
          //   column: 'marketplace.vendorId',
          //   id: 'vendorId',
          //   field: {
          //     label: 'Vendor',
          //     type: 'autocomplete',
          //     items: this.vendorsList,
          //   },
          // },
        ];
      },
    },
    methods: {
      async loadData() {
        // const [vendorsList] = await Promise.all([
        //   http.get(`/vendors/list`),
        // ]);
        // this.vendorsList = vendorsList;
      },
      async getCommits(item) {
        this.loading = true;
        try {
          await http.get(`/projects/${this.projectId}/providers/${item.id}/commits`);
          await this.onSaved();
          bus.trigger('load-services');
        } finally {
          this.loading = false;
        }
      },
      onSaved() {
        this.$refs.table.fetchData();
      },
    },
  };
</script>
