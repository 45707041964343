<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-tooltip bottom>
      <template
        v-slot:activator="{ on }"
      >
        <v-btn
          class="ma-0"
          v-on="on"
          icon
          @click="showDialog = true"
        >
          <v-icon>mdi-table-column</v-icon>
        </v-btn>
      </template>
      <span>Select Columns</span>
    </v-tooltip>
    <v-dialog
      persistent
      v-model="showDialog"
      scrollable
    >
      <v-card>
        <v-card-title class="headline">
          Columns
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col
                cols="6"
                md="4"
                lg="3"
                v-for="input in columnInputs"
                :key="input.value"
              >
                <v-checkbox
                  class="ma-0"
                  dense
                  :inputValue="selectedColumnIds"
                  @change="$emit('update:selectedColumnIds', $event)"
                  :value="input.value"
                  color="primary"
                  :label="input.label"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="showDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'ColumnsMenu',
    props: {
      selectedColumnIds: Array,
      columns: Array,
    },
    data() {
      return {
        showDialog: false,
      };
    },
    computed: {
      columnInputs() {
        return this.columns
          .filter((column) => !column.hide)
          .map((column) => ({
            label: column.text,
            value: column.id || column.name,
            checked: this.selectedColumnIds.indexOf(column.id) > -1,
          }));
      },
    },
    methods: {
    },
  };
</script>
