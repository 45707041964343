import Vue from 'vue';
import VueRouter from 'vue-router';

import store from './@state/store';

import Dashboard from './App/Dashboard';
import Login from './App/Login';
import Project from './App/Project';

Vue.use(VueRouter);


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        public: false,
        title: 'Dashboard - DevOps',
        layout: 'default-layout',
      },
    },
    {
      path: '/projects/:projectId',
      name: 'project',
      component: Project,
      meta: {
        public: false,
        title: 'Project - DevOps',
        layout: 'default-layout',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        public: true,
        title: 'Login - Citadel',
        layout: 'empty-layout',
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  // WARNING: https://github.com/vuejs/vue-router/issues/2873
  // WARNING: read above when dynamically routing such as this.$router.replace

  document.title = to.meta.title;

  if (!store.state.initialized) {
    await store.dispatch('initialize');
  }
  const user = store.state.users.user;
  if (!user && !to.meta.public) {
    next('/login');
  } else {
    next();
  }
});

export default router;
