<template>
  <c-form
    :minContentHeight="350"
    ref="form"
    :url="url"
    :form="form"
    :editingItem="user"
    showCancel
    @saved="onSaved"
    @cancel="onCancel"
    @change="onChange"
    id="dialog-form"
    @itemChange="item = $event"
  ></c-form>
</template>

<style lang="scss">
</style>

<script>
  import rules from '@/@libs/validations';

  export default {
    name: 'Password',
    components: {
    },
    props: {
    },
    data() {
      return {
        item: {},
        url: '/users/change-password',
      };
    },
    computed: {
      user() {
        return this.$store.state.users.user;
      },
      form() {
        return {
          name: 'User',
          defaultValue: {
            multiFactorActive: false,
            role: null,
          },
          fields: [
            {
              label: 'Current Password',
              name: 'currentPassword',
              type: 'password',
              rules: [
                rules.required(),
              ],
            },
            {
              label: 'New Password',
              name: 'password',
              type: 'password',
              rules: [
                rules.required(),
              ],
            },
            {
              label: 'Confirm New Password',
              name: 'confirmNewPassword',
              type: 'password',
              rules: [
                rules.required(),
                (confirmPassword) => {
                  if (confirmPassword !== this.item.password) {
                    return 'Password must match';
                  }
                  return true;
                },
              ],
            },
          ],
        };
      },
    },
    methods: {
      onChange(item) {
        this.item = item;
      },
      onSaved(user) {
        this.$emit('saved', user);
        this.$store.commit('users/setUser', user);
      },
      onCancel() {
        this.$emit('cancel');
      },
    },
  };
</script>
