import Vue from 'vue';
import Vuex from 'vuex';

import users from './users';
import constants from './constants';

Vue.use(Vuex);

const modules = {
  users,
  constants,
};

export default new Vuex.Store({
  modules,
  state: {
    initialized: false,
  },
  mutations: {

  },
  actions: {
    async initialize({ state, dispatch }) {
      // I'm not sure what the following comment is talking about. Maybe it's wrong
      // do this first because the next dispatch will throw error if not logged in
      try {
        await Promise.all([
          dispatch('users/loadUser'),
          dispatch('constants/fetchConstants'),
        ]);
      } finally {
        // suposedly putting this after the dispatched caused some error, but I don't see it.
        // if I do put this back then it will cause an issue in Default.vue showSideMenu making it flash
        state.initialized = true;
      }
    },
  },
});
