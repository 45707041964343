<template>
  <div class="projects-table">
    <c-data-table
      ref="table"
      id="generic-table"
      uid="id"
      search
      :selectedFilters.sync="selectedFilters"
      :url="url"
      :columns="columns"
      :filters="filters"
      :actions="actions"
      load-all
    >
      <template v-slot:item.label="{ item }">
        <router-link
          :to="`/projects/${item.id}`"
        >
          {{item.label}}
        </router-link>
      </template>
    </c-data-table>

    <ProjectsForm
      v-if="showForm"
      :show.sync="showForm"
      :duplicateId="duplicateId"
      :id="editingId"
      :url="url"
      @saved="onSaved"
    ></ProjectsForm>
  </div>
</template>

<style lang="scss">
</style>

<script>
  import http from '@/@libs/http';
  import ProjectsForm from './ProjectsForm';

  export default {
    name: 'ProjectsTable',
    props: {},
    components: {
      ProjectsForm,
    },
    created() {
      this.loadData();
    },
    data() {
      return {
        showForm: false,
        editingId: null,
        duplicateId: null,
        selectedFilters: [],
        columns: [
          {
            id: 'id',
            text: 'id',
            sortable: false,
            value: 'id',
            default: false,
          },
          {
            id: 'label',
            text: 'Label',
            sortable: false,
            value: 'label',
            default: true,
          },
          {
            id: 'created',
            text: 'Created',
            value: 'created',
            type: 'date',
            sortable: true,
            default: true,
          },
          {
            id: 'updated',
            text: 'Updated',
            value: 'updated',
            type: 'date',
            sortable: true,
          },
          {
            id: 'actions',
            text: 'Actions',
            align: 'left',
            sortable: false,
            default: true,
            width: 120,
            actions: [
              {
                text: 'Edit',
                icon: 'mdi-pencil',
                onClick: (item) => {
                  this.duplicateId = null;
                  this.editingId = item.id;
                  this.showForm = true;
                },
              },
              {
                text: 'Delete',
                icon: 'mdi-delete',
                emit: 'delete',
                // onClick: () => {},
              },
            ],
          },
        ],
        actions: [
          {
            text: 'Create',
            icon: 'mdi-plus',
            // emit: 'create',
            onClick: () => {
              this.showForm = true;
              this.editingId = null;
            },
          },
        ],
      };
    },
    computed: {
      url() {
        return '/projects';
      },
      filters() {
        return [
          // {
          //   type: '$eq',
          //   column: 'marketplace.vendorId',
          //   id: 'vendorId',
          //   field: {
          //     label: 'Vendor',
          //     type: 'autocomplete',
          //     items: this.vendorsList,
          //   },
          // },
        ];
      },
    },
    methods: {
      async loadData() {
        // const [vendorsList] = await Promise.all([
        //   http.get(`/vendors/list`),
        // ]);
        // this.vendorsList = vendorsList;
      },
      onSaved() {
        this.$refs.table.fetchData();
      },
    },
  };
</script>
