import Vue from 'vue';
import Form from './Form';
import FormBox from './FormBox';
import FormFields from './FormFields';
import Toolbar from './Toolbar';
import DataTable from './DataTable';
import Table from './Table';

Vue.component('c-form', Form);
Vue.component('c-form-box', FormBox);
Vue.component('c-form', Form);
Vue.component('c-data-table', DataTable);
Vue.component('c-table', Table);
Vue.component('c-toolbar', Toolbar);
Vue.component('c-form-fields', FormFields);
