<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    v-model="showModalAlert"
    :max-width="maxWidth"
    @keydown.enter="close"
  >
    <v-card>
      <v-card-title>{{title}}</v-card-title>

      <v-card-text>
        <span v-html="text"></span>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>

        <v-btn
          @click="close"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
</style>

<script>
  import bus from '@/@libs/bus';

  export default {
    name: 'ModalAlert',
    components: {
    },
    props: {
    },
    data() {
      return {
        title: '',
        text: '',
        showModalAlert: false,
        maxWidth: 290,
      };
    },
    mounted() {
      bus.on('modalAlert', this.openModalAlert);
    },
    beforeDestroy() {
      bus.off('modalAlert', this.openModalAlert);
    },
    computed: {
    },
    methods: {
      close() {
        this.title = '';
        this.text = '';
        this.showModalAlert = false;
      },
      openModalAlert({ title, text, maxWidth = 290 }) {
        this.showModalAlert = true;
        this.title = title;
        this.text = text;
        this.maxWidth = maxWidth;
      },
    },
  };
</script>
