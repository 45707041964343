<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <div v-for="(field, index) in fields" :key="index" >
      <template v-if="!field.hide">
        <template v-if="['text', 'number', 'password'].indexOf(field.type) > -1">
          <v-text-field
            v-if="field.mask"
            v-model="item[field.name]"
            :rules="field.rules"
            :type="field.type"
            :label="field.label"
            :disabled="field.disabled"
            :placeholder="field.placeholder"
            v-mask="field.mask"
          ></v-text-field>
          <v-text-field
            v-else
            v-model="item[field.name]"
            :rules="field.rules"
            :type="field.type"
            :label="field.label"
            :disabled="field.disabled"
            :placeholder="field.placeholder"
          ></v-text-field>
        </template>
        <template v-else-if="field.type === 'textarea'">
          <v-textarea
            filled
            v-model="item[field.name]"
            :rules="field.rules"
            :type="field.type"
            :label="field.label"
            :disabled="field.disabled"
            :placeholder="field.placeholder"
          ></v-textarea>
        </template>
        <template v-else-if="field.type === 'checkbox'">
          <v-checkbox
            v-model="item[field.name]"
            :rules="field.rules"
            :type="field.type"
            :label="field.label"
            :placeholder="field.placeholder"
            :true-value="field.trueValue"
            :disabled="field.disabled"
            :false-value="field.falseValue"
          ></v-checkbox>
        </template>
        <template v-else-if="field.type === 'boolean'">
          <v-select
            autocomplete="off"
            v-model="item[field.name]"
            :rules="field.rules"
            :clearable="field.clearable"
            :label="field.label"
            :disabled="field.disabled"
            :items="[{value: true, text: 'True'}, {value: false, text: 'False'}]"
          ></v-select>
        </template>
        <template v-else-if="field.type === 'switch'">
          <v-switch
            v-model="item[field.name]"
            :rules="field.rules"
            :type="field.type"
            :label="field.label"
            :disabled="field.disabled"
            :placeholder="field.placeholder"
          ></v-switch>
        </template>
        <template v-else-if="field.type === 'treeview'">
          <v-treeview
            v-model="item[field.name]"
            :items="field.items"
            open-all
            activatable
            open-on-click
            selectable
          >
          </v-treeview>
        </template>
        <template v-else-if="field.type === 'select'">
          <v-select
            autocomplete="off"
            v-model="item[field.name]"
            :rules="field.rules"
            :clearable="field.clearable"
            :label="field.label"
            :items="field.items"
            :multiple="field.multiple"
            :disabled="field.disabled"
          ></v-select>
        </template>
        <template v-else-if="field.type === 'autocomplete'">
          <v-autocomplete
            autocomplete="off"
            v-model="item[field.name]"
            :rules="field.rules"
            :clearable="field.clearable"
            :label="field.label"
            :items="field.items"
            :multiple="field.multiple"
            :disabled="field.disabled"
          ></v-autocomplete>
        </template>
        <template v-else-if="field.type === 'offers-autocomplete'">
          <c-offers-autocomplete
            autocomplete="off"
            v-model="item[field.name]"
            :rules="field.rules"
            :clearable="field.clearable"
            :label="field.label"
            :items="field.items"
            :multiple="field.multiple"
            :disabled="field.disabled"
          ></c-offers-autocomplete>
        </template>
        <template v-else-if="field.type === 'httpAutocomplete'">
          <c-http-autocomplete
            autocomplete="off"
            v-model="item[field.name]"
            :rules="field.rules"
            :url="field.url"
            :clearable="field.clearable"
            :label="field.label"
            :multiple="field.multiple"
            :disabled="field.disabled"
            :hint="field.hint"
            :persistent-hint="!!field.hint"
          ></c-http-autocomplete>
        </template>
        <template v-else-if="field.type === 'date'">
          <c-date
            v-model="item[field.name]"
            :rules="field.rules"
            :type="field.dateType"
            :clearable="field.clearable"
            :label="field.label"
            :disabled="field.disabled"
          ></c-date>
        </template>
        <template v-else-if="field.type === 'color'">
          <c-color-input
            v-model="item[field.name]"
            :rules="field.rules"
            :clearable="field.clearable"
            :label="field.label"
            :disabled="field.disabled"
          ></c-color-input>
        </template>
        <template v-else>
          <component
            v-model="item[field.name]"
            :is="field.type"
            v-bind="field"
          ></component>
        </template>
      </template>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'Form',
    props: {
      fields: Array,
      // So yeah we are mutating this but whatever
      item: Object,
    },
    data() {
      return {};
    },
    methods: {
    },
  };
</script>
