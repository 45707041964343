import { render, staticRenderFns } from "./ColumnsMenu.vue?vue&type=template&id=1aa1f15a&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
import script from "./ColumnsMenu.vue?vue&type=script&lang=js&"
export * from "./ColumnsMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports